import React from 'react'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from 'gatsby'


function Button({to, text, linkType}) {

  switch(linkType){
    case 'anchor':
        return (
            <AnchorLink to={to} stripHash className="hero-small-text rounded link bg-yellow hover:bg-yellow-hover px-8 py-4 text-xl">{text}</AnchorLink>
            )
    case 'internal':
        return (
            <Link to={to} stripHash className="hero-small-text rounded link bg-yellow hover:bg-yellow-hover px-8 py-4 text-xl">{text}</Link>
            )
    case 'external':
        return (
            <a href={to} target="_blank" className="hero-small-text rounded link bg-yellow hover:bg-yellow-hover px-8 py-4 text-xl">{text}</a>
            )
  }
}

export default Button
