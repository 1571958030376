import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { BrowserView, MobileView } from 'react-device-detect'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { ElfsightWidget } from 'react-elfsight-widget'
import HeroSlider, { Slide, Nav } from 'hero-slider'
import { isBrowser } from 'react-device-detect'
import Button from '../components/Form/Button'

let image1, image2, image3, image4, image5, image6

if (isBrowser) {
  image1 =
    'https://images.prismic.io/puro001/a6a39183-0b3e-4fb4-b9d6-42b47217b371_Puro-Facilities-Marlborough.jpg?auto=compress,format'
  image2 =
    'https://images.prismic.io/puro001/ce316a25-4db6-420c-836e-3281eaccaa19_Puro-Indoor-Cannabis-Facilities-Marlborough.jpg?auto=compress,format'
  image3 =
    'https://images.prismic.io/puro001/03ee57f9-0c95-4e13-bea3-8dd946777787_Puro-Team-Photo-Marlborough.jpg?auto=compress,format'
  image4 =
    'https://images.prismic.io/puro001%2Fff7f8ef2-ab11-4b07-91a7-e8124adf5f3c_homepage+banner.jpg?auto=compress,format'
  image5 =
    'https://images.prismic.io/puro001/f4fb2619-d225-49db-b371-ab19b64ce976_Puro-Facilities-Marlborough-2.jpg?auto=compress,format'
  image6 =
    'https://images.prismic.io/puro001/57483328-3a17-491f-9317-bcb7fdd3ab30_Megical-Cannabis-Heads.jpg?auto=compress,format'
} else {
  image1 =
    'https://images.prismic.io/puro001/9d68a2e6-8160-487e-bd77-bcb8430f8ce5_Puro-Facilities-Marlborough-Mobile.jpg?auto=compress,format'
  image2 =
    'https://images.prismic.io/puro001/484298fc-a953-4313-9e12-fcef0de80426_Puro-Indoor-Cannabis-Facilities-Marlborough-Mobile.jpg?auto=compress,format'
  image3 =
    'https://images.prismic.io/puro001/03ee57f9-0c95-4e13-bea3-8dd946777787_Puro-Team-Photo-Marlborough.jpg?auto=compress,format'
  image4 =
    'https://images.prismic.io/puro001%2Fff7f8ef2-ab11-4b07-91a7-e8124adf5f3c_homepage+banner.jpg?auto=compress,format'
  image5 =
    'https://images.prismic.io/puro001/cf9147c4-f279-43e4-b730-c210b22a284d_Puro-Facilities-Marlborough-2-Mobile.jpg?auto=compress,format'
  image6 =
    'https://images.prismic.io/puro001/6079a3ca-08c7-421f-9258-55f1a00b04fa_Medical-Cannabis-Heads-Mobile.jpg?auto=compress,format'
}

class IndexPage extends React.Component {
  render() {
    const { data, location } = this.props
    const page = data.prismicHome.data
    console.log(page)
    return (
      <Layout>
        <SEO
          title={page.meta_title}
          description={page.meta_description}
          location={location}
        />
        <div className="overflow-hidden content">
          <div className="pin-t pin-l w-full md:h-auto md:min-h-100 md:max-h-screen md:block">
            <HeroSlider
              color="red"
              slidingAnimation="fade"
              orientation="horizontal"
              initialSlide={1}
              style={{
                backgroundColor: '#414141',
              }}
              settings={{
                slidingDuration: 250,
                slidingDelay: 100,
                shouldAutoplay: true,
                shouldDisplayButtons: true,
                autoplayDuration: 5000,
                height: '100vh',
              }}
            >
              <Slide
                background={{
                  backgroundImage: image1,
                }}
              />

              <Slide
                background={{
                  backgroundImage: image2,
                }}
              />

              <Slide
                background={{
                  backgroundImage: image3,
                }}
              />

              <Slide
                background={{
                  backgroundImage: image4,
                }}
              />

              <Slide
                background={{
                  backgroundImage: image5,
                }}
              />

              <Slide
                background={{
                  backgroundImage: image6,
                }}
              />

              <Nav />
            </HeroSlider>
          </div>

          <div className="mt-5 lg:mb-6 lg:mt-20">
            <div className="grid">
              <div className="grid__half--primary">
                <div className="w-3/4">
                  <h1 className="type-xl lg:type-3xl text-white pt-4 lg:pt-0">
                    {page.title}
                  </h1>
                </div>
              </div>
              <div className="grid__half--secondary lg:type-lg rte text-justify">
                <div dangerouslySetInnerHTML={{ __html: page.lead.html }} />
              </div>
            </div>
          </div>
          {/*}
          <div className="lg:my-10 pb-12 lg:pb-30 md:hidden">
            <div className="relative pin-t pin-l w-full h-auto">
              <div className="absolute m-auto top-0 bottom-0 right-0 left-0 md:hidden justify-center items-center text-center flex flex-col">
                <div>
                  <img src={page.puro_invest_banner_logo.url} className="hero-image mx-auto mb-4" />
                  <span className="text-white font-medium text-3xl hero-big-text">Capital Raise Now Live</span>
                </div>
                <div className="mt-4">
                  <a href="https://pledgeme.co.nz/investments/458-puro-new-zealand" target="_blank" className="hero-small-text rounded link bg-yellow hover:bg-yellow-hover px-8 py-4 text-xl">Invest Today</a>
                  <AnchorLink to="/invest#invest-form" stripHash className="hero-small-text rounded bg-yellow hover:bg-yellow-hover px-4 py-2 text-xl">Invest Today</AnchorLink>
                </div>
              </div>
              <img className="object-cover md:hidden w-full" src={page.hero.url} alt={page.hero.alt} />
            </div>
          </div>
          {*/}
          <div className="grid">
            <div className="grid__full border-b-2 border-white" />
          </div>
          <div className="lg:my-8">
            <div className="grid">
              <div className="grid__full type-base rte lg:my-10 py-10 lg:py-0">
                <p className="lg:type-2xl font-light italic text-white text-center">
                  "{page.testimonial.text}"
                </p>
                <div className="lg:mt-10 text-white lg:type-xl text-right">
                  &mdash; {page.testimonial_credit}
                </div>
              </div>
            </div>
          </div>
          <div className="grid mb-12 lg:mb-20">
            <div className="grid__full border-b-2 border-white" />
          </div>

          <div>
            <div className="grid">
              <div className="grid__half--primary">
                <div>
                  <div>
                    {page.primary_video.url !== '' && (
                      <>
                        <video
                          className="focus:outline-none"
                          controls={true}
                          poster={page.primary_video_poster.url}
                        >
                          <source src={page.primary_video.url} />
                        </video>
                        <p className="pt-2">{page.primary_video_poster.alt}</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid__half--secondary">
                {page.secondary_video.url !== '' && (
                  <>
                    <video
                      className="focus:outline-none"
                      controls={true}
                      poster={page.secondary_video_poster.url}
                    >
                      <source src={page.secondary_video.url} />
                    </video>
                    <p className="pt-2">{page.secondary_video_poster.alt}</p>
                  </>
                )}
              </div>

              <div className="grid__half--primary-row-two">
                <a href="/news/13m-grant-turbocharges-nzs-medicinal-cannabis-industry">
                  <div className="relative announcement">
                    <img
                      className="announcement__image"
                      src="https://images.prismic.io/puro001/43072973-2b71-4426-a51b-7549fcb04d2c_puro-government-grant.jpg?auto=compress,format"
                    />
                    <div className="absolute inset-2 block text-xl text-white bottom-0 pb-2 announcement__inner">
                      <h1 className="mb-2 md:mb-3 font-black tracking-widest text-sm md:text-xl">
                        Puro receives $13 million Govt grant
                      </h1>
                      <span className="tracking-wide no-underline font-black text-sm md:text-md rounded link bg-yellow-hover hover:bg-yellow-hover px-6 py-2">
                        Read More
                      </span>
                    </div>
                  </div>
                </a>
                <p className="-mt-2">
                  Puro receives multi-million dollar government grant
                </p>
              </div>
              <div className="grid__half--secondary-row-two">
                <img src={page.image.url} alt={page.image.alt} />
                <p className="pt-2">{page.image.alt}</p>
              </div>

              <div className="grid__half--primary-row-three">
                <div>
                  <img
                    src={page.bottom_image_top_right.url}
                    alt={page.bottom_image_top_right.alt}
                    className="w-full"
                  />
                </div>
                <p className="pt-2">{page.bottom_image_top_right.alt}</p>
              </div>
              <div className="grid__half--secondary-row-three">
                <div>
                  <img
                    src={page.bottom_image_bottom_right.url}
                    alt={page.bottom_image_bottom_right.alt}
                  />
                  <p className="pt-2">{page.bottom_image_bottom_right.alt}</p>
                </div>
              </div>
            </div>
            <div className="grid mt-00 lg:mt-0">
              <div className="grid__full border-b-2 border-white pb-10 mb-4 lg:mt-4 lg:mb-8">
                <ElfsightWidget widgetID="f5497ad7-8ca4-4b3b-9ad4-905d74a47dee" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageHomeQuery = graphql`
  {
    prismicHome {
      data {
        hero {
          alt
          copyright
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        hero_image_mobile {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        puro_invest_banner_logo {
          url
          alt
        }
        title
        lead {
          html
        }
        secondary_title
        secondary_text {
          html
          text
        }
        testimonial {
          html
          text
        }
        testimonial_credit
        image {
          alt
          copyright
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 360) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        bottom_image_top_right {
          alt
          copyright
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 360) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        bottom_image_bottom_left {
          alt
          copyright
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 360) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        bottom_image_bottom_right {
          alt
          copyright
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 360) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        primary_link {
          url
        }
        primary_link_faq_index
        primary_link_header
        primary_link_image {
          url
          alt
        }
        primary_video {
          url
        }
        primary_video_poster {
          url
          alt
        }
        secondary_video {
          url
        }
        secondary_video_poster {
          url
          alt
        }
        secondary_link {
          url
        }
        secondary_link_faq_index
        secondary_link_header
        secondary_link_image {
          url
          alt
        }
        page_bottom_text {
          html
        }
        meta_title
        meta_description
      }
    }
  }
`

export default IndexPage
